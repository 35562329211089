import React,{useEffect,useState,useRef} from 'react'
import { motion } from "framer-motion";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function TataNexon() {
  
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utm_source = searchParams.get('utm_source');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
console.log(utm_source);
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    mobile:'',
    state:'',
    city:'',
    model:'nexon ev',
    utm_source:''
   
  });
  const title = "Tata Nexon EV";
  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      utm_source: utm_source
    }));
  }, [utm_source]);
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value});
  }
  const validateForm = () => {
    let formIsValid = true;
    let tempErrors = {};

    // Validate name
    if (!formData.name) {
      tempErrors.name = 'Name is required';
      formIsValid = false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      tempErrors.email = 'Invalid email address';
      formIsValid = false;
    }

    // Validate mobile number (should be 10 digits)
    const mobilePattern = /^[6-9]\d{9}$/;
    if (!formData.mobile || !mobilePattern.test(formData.mobile)) {
      tempErrors.mobile = 'Invalid mobile number (should be 10 digits and start with 6-9)';
      formIsValid = false;
    }

    // Validate city and state
    if (!formData.city) {
      tempErrors.city = 'City is required';
      formIsValid = false;
    }
    if (!formData.state) {
      tempErrors.state = 'State is required';
      formIsValid = false;
    }

    // Check if terms are accepted
    if (!isTermsAccepted) {
      tempErrors.terms = 'You must accept the terms and conditions';
      formIsValid = false;
    }

    setErrors(tempErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log(formData);
      axios
        .post('https://api.tataevcars.info/submitLead', formData)
        .then((response) => {
          if (response.data.status === true) {
            toast.success('Thank you for contacting us!');
          } else if (response.data.status === false) {
            toast.error('You have already enquired. We will contact you ASAP!');
          }
        })
        .catch((err) => {
          toast.error('Something went wrong. Please try again later.');
          console.log(err);
        });
    } else {
      toast.error('Please correct the highlighted fields.');
    }
  };


  const settings = {
    dots: true,  // Enables the dots below the slider
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1
  };

  const slidesData = [
    
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/smart-digital-x-factor-new.jpg`,
      heading: 'Smart Digital X Factor',
      content: 'The iconic X factor with a welcome and goodbye sequence lends a sleek digital look.'
    }
    ,
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/all-new-digital-design-new1.jpg`,
      heading: 'Smart Digital DRLs',
      content: 'Make your presence felt through the signature DRLs. '
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/hidden-rear-wiper-new.jpg`,
      heading: 'Hidden Rear Wiper',
      content: 'Better coverage for better visibility.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/r16-alloy-wheels.jpg`,
      heading: 'R16 Alloy Wheels',
      content: 'Glide through with aero inserts on R16 alloy wheels.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/full-dashboard.jpg`,
      heading: 'Digital Dashboard',
      content: 'A dynamic digital dashboard that elevates your cabin experience.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/smart-digital-steering-wheel.jpg`,
      heading: 'Smart Digital Steering Wheel',
      content: 'Stylish 2-spoke back-lit steering wheel.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/arcade-ev-2.jpg`,
      heading: 'Big Touchscreen',
      content: '31.24 cm Cinematic Touchscreen by HARMAN'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/high-quality-speakers.jpg`,
      heading: '9 Speakers',
      content: '9 high quality speakers including external sub-woofer'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/360-surround-view-camera-system-3.jfif`,
      heading: '360° Surround View Camera System',
      content: 'Get a 360° view of everything that surrounds you.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/nexon-safety-bncap-image.jfif`,
      heading: 'BNCAP 5-star rating',
      content: '29.86/32 in Adult Occupancy Protection*. 44.95/49 in Child Occupancy Protection*'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/esp-with-ivbac.jfif`,
      heading: 'ESP with iVBAC',
      content: 'Advanced dynamic stability for precise handling.'
    }
    ,
    {
      image: `${process.env.PUBLIC_URL}/images/nexon/front-parking-sensor-9.jfif`,
      heading: 'Front Parking Sensor',
      content: 'Parking made easier with front assist sensors.'
    }
  ];
  return (
    <>
        
        <ToastContainer
position="top-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
        <motion.section  id="section1">
        <div className='container-fluid p-0 m-0  d-flex align-items-center' id="nexon">
            <div className='row' style={{width:"100%"}}>
            <div className='col-md-8'></div>
            <div className='col-md-4 ps-5'>
                
                
                  <form id='TataForm'>
                  <h2 className='headh1  text-white mb-0'>Nexon.ev</h2>
                  
                <h5 className='fw-light text-white' style={{textShadow:"0 0 5px #000000"}}>A Price Drop of <b>&#8377;3L+</b> That Makes It Par With Petrol/Diesel Cars</h5>
                  <div className='mb-3'>
                      <input type="text" name='name' value={formData.name} onChange={handleChange} className="form-control" placeholder="Enter Your Name" />
                      {errors.name && <span className="text-white">{errors.name}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="email" name='email' value={formData.email} onChange={handleChange} className="form-control" placeholder="Enter Your Email" />
                      {errors.email && <span className="text-white">{errors.email}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="text" name='mobile' value={formData.mobile} onChange={handleChange} className="form-control" placeholder="Enter Your Mobile Number" />
                      {errors.mobile && <span className="text-white">{errors.mobile}</span>}
                    </div>
                    <div className='row'>
                    <div className='mb-3 col-12 col-md-6'>
                      <input type="text" name='city' value={formData.city} onChange={handleChange} className="form-control" placeholder="Enter City" />
                      {errors.city && <span className="text-white">{errors.city}</span>}
                    </div>
                    <div className='mb-3 col-12 col-md-6'>
                      <input type="text" name='state' value={formData.state} onChange={handleChange} className="form-control" placeholder="Enter State" />
                      {errors.state && <span className="text-white">{errors.state}</span>}
                    </div>
                    </div>
                    <input type='hidden' name='model' value={formData.model} />
                    <div className="mb-3 input-wrapper">
                    <input
                      type="checkbox"
                      id="terms"
                      onChange={handleTermsChange}
                      checked={isTermsAccepted}
                    />
                    <label htmlFor="terms " className='text-white'>
                      &nbsp;I Accept <a href="#" className='text-white' target="_blank">Terms and Conditions</a> <span className="text-white">*</span>
                    </label><br/>
                    {errors.terms && <span className="text-white">{errors.terms}</span>}
                  </div>
                    <div className='mb-3 text-center'>
                      <button type="button" className='btn btn-default' onClick={handleSubmit} >Submit Enquiry</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        </motion.section>

        <motion.section  className='bg-dark py-3 d-flex align-items-center' id="section2">
          <div className='container'>
            <div className='row text-center'>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Range</h2>
              <h5 className='text-white fw-light'>Certified full charge range as per
              MIDC Part 1 + Part 2 (km) : 489# km</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Fast Charge</h2>
              <h5 className='text-white fw-light'>40 min*</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>0 to 100kmph</h2>
              <h5 className='text-white fw-light'>8.9^ sec</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Safety</h2>
              <h5 className='text-white fw-light'>5 star rated safety</h5>
            </div>
            </div>
          </div>
        </motion.section>
        <motion.section  className='py-3 d-flex align-items-center' id="section3">
          <div className='container'>
            <div className='row'>
            <div className='col-12 text-center'>
            <h2 className='section2-card-head'>Features</h2>
            </div>
            <Slider {...settings}>
              {slidesData.map((slide, index) => (
                <div key={index} className="slide">
                  <div className="slide-content">
                    <div className="image">
                      <img src={slide.image} alt={`Slide ${index + 1}`} style={{borderRadius:"4px"}} className='img-fluid'/>
                    </div>
                    <div className="text-content ps-3">
                      <h2>{slide.heading}</h2>
                      <p>{slide.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            </div>
          </div>
        </motion.section>
        <motion.section className="d-flex align-items-center" id="section4" style={{backgroundColor:"#000 !important"}}>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
              <iframe
                className="w-100"
                height="500" // You can adjust the height as needed
                src="https://www.youtube.com/embed/uT2drw7r61w?autoplay=1&mute=1&controls=0&loop=1&playlist=uT2drw7r61w"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              </div>
            </div>
          </div>
        </motion.section>

       
        


        <motion.section class="d-flex align-items-center" id="section5">
          <div class="container pb-5 ">
            <div class="row">
              <div className='col-12 text-center'>
              <h2 className='section2-card-head'>Colors</h2>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/nexon/colors/PristineWhite-0-1.jfif`} className='img-fluid' />
                <h6>Pristine White</h6>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/nexon/colors/Oxide-0-2.jfif`} className='img-fluid' />
                <h6>Oxide</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/nexon/colors/IntensiTeal-0-2.jfif`} className='img-fluid' />
                <h6>Intesi Teal</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/nexon/colors/FlameRed-0-3.jfif`} className='img-fluid' />
                <h6>Flame Red</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/nexon/colors/DaytonaGrey-0-4.jfif`} className='img-fluid' />
                <h6>Daytona Grey</h6>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section className="bg-dark py-5 d-flex align-items-center" id="section6">
          <div className="container p-0">
            <div className="row text-center">
            <div className='col-12'><h4 className='section2-card-head text-white'>Nexon.ev Architecture</h4></div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/nexon/motor-8.jfif`} className='img-fluid' />
             <h6 className=' text-white'>All New Gen-2 Motor</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/nexon/nexon-battery-45.jfif`} className='img-fluid' />
             <h6 className=' text-white'>Liquid Cooled IP67 Rated Battery Pack</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/nexon/power-electronics.jfif`} className='img-fluid' />
             <h6 className=' text-white'>Power Electronics</h6>
             </div>
             </div>
            </div>
          </div>
        </motion.section>

        <motion.section className=" d-flex align-items-center" id="section6">
          <div className="container p-0">
          <div className='row'>
          <div className='col-12 text-center pb-5'><h4 className='section2-card-head '>Schedule A Test Drive</h4></div>
          </div>
            <div className="row text-center  d-flex align-items-center">
           
             <div className='col-12 col-md-6'>
             <img src={`${process.env.PUBLIC_URL}/images/nexon/book-a-test-drive-2.jfif`} className='img-fluid' style={{borderRadius:"4px"}}/>
             
             </div>
             <div className='col-12 col-md-6'>
             
             <h4 className='fw-light'>We know you will love our EVs, which is why you can book a test drive online at the nearest dealership.</h4>
             <button className='btn btn-primary' >Book Test Drive</button>
             </div>
             
            </div>
          </div>
        </motion.section>




    </>
  )
}

export default TataNexon