import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import './App.css';
import TataNexon from './pages/TataNexon';
import TataPunch from './pages/TataPunch';
import TataTiago from './pages/TataTiago';
import TataCurvv from './pages/TataCurvv';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Nexon_Ev" element={<TataNexon />} />
        <Route exact path="/Punch_Ev" element={<TataPunch />} />
        <Route exact path="/Tiago_Ev" element={<TataTiago />} />
        <Route exact path="/Curvv_Ev" element={<TataCurvv/>} />
      </Routes>
      
    </Router>
  );
}

export default App;
