import React,{useEffect,useState,useRef} from 'react'
import { motion } from "framer-motion";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function TataCurvv() {
  
  /*
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aff_id = searchParams.get('aff_id');*/
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});
  const handleTermsChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utm_source = searchParams.get('utm_source');
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    mobile:'',
    state:'',
    city:'',
    model:'curvv ev',
    utm_source: ''
   
  })

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value});
  }
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      utm_source: utm_source
    }));
  }, [utm_source]);
  const validateForm = () => {
    let formIsValid = true;
    let tempErrors = {};

    // Validate name
    if (!formData.name) {
      tempErrors.name = 'Name is required';
      formIsValid = false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      tempErrors.email = 'Invalid email address';
      formIsValid = false;
    }

    // Validate mobile number (should be 10 digits)
    const mobilePattern = /^[6-9]\d{9}$/;
    if (!formData.mobile || !mobilePattern.test(formData.mobile)) {
      tempErrors.mobile = 'Invalid mobile number (should be 10 digits and start with 6-9)';
      formIsValid = false;
    }

    // Validate city and state
    if (!formData.city) {
      tempErrors.city = 'City is required';
      formIsValid = false;
    }
    if (!formData.state) {
      tempErrors.state = 'State is required';
      formIsValid = false;
    }

    // Check if terms are accepted
    if (!isTermsAccepted) {
      tempErrors.terms = 'You must accept the terms and conditions';
      formIsValid = false;
    }

    setErrors(tempErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      axios
        .post('https://api.tataevcars.info/submitLead', formData)
        .then((response) => {
          if (response.data.status === true) {
            toast.success('Thank you for contacting us!');
          } else if (response.data.status === false) {
            toast.error('You have already enquired. We will contact you ASAP!');
          }
        })
        .catch((err) => {
          toast.error('Something went wrong. Please try again later.');
          console.log(err);
        });
    } else {
      toast.error('Please correct the highlighted fields.');
    }
  };


  const settings = {
    dots: true,  // Enables the dots below the slider
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1
  };

  const slidesData = [
    
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/flush-door-handles-1.jpg`,
      heading: 'Flush door handles',
      content: 'Enhanced vehicle asthetics while adding to aerodynamic efficiency.'
    }
    ,
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/voice-assisted-panoramic-sunroof-with-mood-lighting-2.jpg`,
      heading: 'Panoramic Sunroof',
      content: 'Enjoy the sky above and set the perfect mood for your journey, all at your command.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/digital-dashboard-3.jpg`,
      heading: 'Digital Dashboard',
      content: 'Confidently navigate the road ahead with better visibility.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/r18-alloy-wheels-with-aero-inserts-2.jpg`,
      heading: 'R18 alloy wheels with Aero Inserts',
      content: 'Your Command Center for the long journeys ahead.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/smart-digital-shifter-2.jpg`,
      heading: 'Smart Digital Shifter',
      content: 'Sleek looks and a futuristic driving experience.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/31-24-cm-12-3-cinematic-touchscreen-by-harman-1.jpg`,
      heading: '31.24 cm (12.3") Cinematic Touchscreen by HARMAN',
      content: 'Bring your infotainment to life with stunning clarity and detail.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/wireless-smartphone-charger-2.jpg`,
      heading: 'Wireless Smartphone Charger',
      content: 'Stay powered up on the go and always ready for your next adventure.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/26-03-cm-10-25-digital-cockpit-1.jpg`,
      heading: '26.03 cm (10.25") Digital Cockpit',
      content: 'Stay informed and in control with customisable displays tailored to your preferences.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/jbl-cinematic-sound-system-1.jpg`,
      heading: 'JBL cinematic sound system',
      content: 'Designed for a rich, high-quality entertainment experience.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/high-quality-speakers-1.jfif`,
      heading: 'High quality speakers',
      content: 'Ensuring every note and beat is delivered with exceptional clarity and depth.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/electric-orvm-with-autofold-1.jpg`,
      heading: 'Electric ORVM with Auto Fold',
      content: 'Convenience at the touch of a button for the perfect rear view.'
    }
    ,
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/adaptive-cruise-control.jpg`,
      heading: 'Adaptive Cruise Control with Stop & Go (ACC)',
      content: 'Cruise through long journeys maintaining a pre-determined distance from the vehicle in front , even in stop-and-go traffic.'
    },
    {
      image: `${process.env.PUBLIC_URL}/images/curvv/lane-change-assist.jpg`,
      heading: 'Lane Departure Warning (LDW)',
      content: 'Get warnings and assistance to prevent unintended lane departures.'
    }
   
  ];
  return (
    <>
    <ToastContainer
position="top-left"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
        <motion.section  id="section1">
        <div className='container-fluid p-0 m-0  d-flex align-items-center' id="curvv">
            <div className='row' style={{width:"100%"}}>
            <div className='col-md-8'></div>
            <div className='col-md-4 ps-5'>
                
                  <form id='TataCurvvForm'>
                  <h2 className='headh1  text-white mb-0'>Curvv.ev</h2>
                  
                <h5 className='fw-light text-white' style={{textShadow:"0 0 5px #000000"}}>Your Time To Go Ahead Of Curve - Is Now</h5>
                    <div className='mb-3'>
                      <input type="text" name='name' value={formData.name} onChange={handleChange} className="form-control" placeholder="Enter Your Name" />
                      {errors.name && <span className="text-white">{errors.name}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="email" name='email' value={formData.email} onChange={handleChange} className="form-control" placeholder="Enter Your Email" />
                      {errors.email && <span className="text-white">{errors.email}</span>}
                    </div>
                    <div className='mb-3'>
                      <input type="text" name='mobile' value={formData.mobile} onChange={handleChange} className="form-control" placeholder="Enter Your Mobile Number" />
                      {errors.mobile && <span className="text-white">{errors.mobile}</span>}
                    </div>
                    <div className='row'>
                    <div className='mb-3 col-12 col-md-12'>
                      <input type="text" name='city' value={formData.city} onChange={handleChange} className="form-control" placeholder="Enter City" />
                      {errors.city && <span className="text-white">{errors.city}</span>}
                    </div>
                    <div className='mb-3 col-12 col-md-12'>
                      <input type="text" name='state' value={formData.state} onChange={handleChange} className="form-control" placeholder="Enter State" />
                      {errors.state && <span className="text-white">{errors.state}</span>}
                    </div>
                    </div>
                    <input type='hidden' name='model' value={formData.model} />
                    <div className="mb-3 input-wrapper">
                    <input
                      type="checkbox"
                      id="terms"
                      onChange={handleTermsChange}
                      checked={isTermsAccepted}
                    />
                    <label htmlFor="terms " className='text-white'>
                      &nbsp;I Accept <a href="#" className='text-white' target="_blank">Terms and Conditions</a> <span className="text-white">*</span>
                    </label><br/>
                    {errors.terms && <span className="text-white">{errors.terms}</span>}
                  </div>
                    <div className='mb-3 text-center'>
                      <button type="button" className='btn btn-default' onClick={handleSubmit} >Submit Enquiry</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        </motion.section>

        <motion.section  className='bg-dark py-3 d-flex align-items-center' id="section2">
          <div className='container'>
            <div className='row text-center'>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Range</h2>
              <h5 className='text-white fw-light'>502# Km</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Fast Charge</h2>
              <h5 className='text-white fw-light'>40 min*</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>0 to 100kmph</h2>
              <h5 className='text-white fw-light'>8.6^ sec</h5>
            </div>
            <div className='col-12 col-md-6 mb-5'>
              <h2 className='section2-card-head text-white'>Safety</h2>
              <h5 className='text-white fw-light'>5 star rated safety</h5>
            </div>
            </div>
          </div>
        </motion.section>
        <motion.section  className='py-3 d-flex align-items-center' id="section3">
          <div className='container'>
            <div className='row'>
            <div className='col-12 mb-3 text-center'>
            <h2 className='section2-card-head'>Curvv.ev Features</h2>
            </div>
            <Slider {...settings}>
              {slidesData.map((slide, index) => (
                <div key={index} className="slide">
                  <div className="slide-content">
                    <div className="image">
                      <img src={slide.image} alt={`Slide ${index + 1}`} style={{borderRadius:"4px"}} className='img-fluid'/>
                    </div>
                    <div className="text-content ps-3">
                      <h2>{slide.heading}</h2>
                      <p>{slide.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            </div>
          </div>
        </motion.section>
        <motion.section className="d-flex align-items-center" id="section4" style={{backgroundColor:"#000 !important"}}>
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
              <iframe
                className="w-100"
                height="500" // You can adjust the height as needed
                src="https://www.youtube.com/embed/ESFrRtt9DbM?autoplay=1&mute=1&controls=0&loop=1&playlist=ESFrRtt9DbM"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              </div>
            </div>
          </div>
        </motion.section>

       
        


        <motion.section class="d-flex align-items-center" id="section5">
          <div class="container pb-5 ">
            <div class="row">
              <div className='col-12 text-center'>
              <h2 className='section2-card-head'>Curvv.ev Colors</h2>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/curvv/colors/PristineWhite-0.jfif`} className='img-fluid' />
                <h6>Pristine White</h6>
              </div>
              <div class="col-6 col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/curvv/colors/PureGrey-0.jfif`} className='img-fluid' />
                <h6>Pure Grey</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/curvv/colors/VirtualSunrise-0.jfif`} className='img-fluid' />
                <h6>Virtual Sunrise</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/curvv/colors/FlameRed-0-2.jfif`} className='img-fluid' />
                <h6>Flame Red</h6>
              </div>
              <div class="col-6  col-md-4 text-center">
                <img src={`${process.env.PUBLIC_URL}/images/curvv/colors/EmpoweredOxide-0.jfif`} className='img-fluid' />
                <h6>Empowered Oxide</h6>
              </div>
            </div>
          </div>
        </motion.section>

        <motion.section className="bg-dark py-5 d-flex align-items-center" id="section6">
          <div className="container p-0">
            <div className="row text-center">
            <div className='col-12'><h4 className='section2-card-head text-white'>Curvv.ev Safe Side</h4></div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/curvv/all-new-gen-motor-new.jfif`} className='img-fluid' />
             <h6 className=' text-white'>All New Gen-2 Motor</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/curvv/liquid-cooled-battery-pack-new.jfif`} className='img-fluid' />
             <h6 className=' text-white'>Liquid Cooled Battery Pack</h6>
             </div>
             </div>
             <div className='col-12 col-md-4'>
             <div className='m-4'>
             <img src={`${process.env.PUBLIC_URL}/images/curvv/ip67-rated-battery-pack-and-motor-new-1.jfif`} className='img-fluid' />
             <h6 className=' text-white'>IP67 rated Battery Pack and Motor</h6>
             </div>
             </div>
            </div>
          </div>
        </motion.section>

        <motion.section className=" d-flex align-items-center" id="section6">
          <div className="container p-0">
          <div className='row'>
          <div className='col-12 text-center pb-5'><h4 className='section2-card-head '>Schedule A Test Drive</h4></div>
          </div>
            <div className="row text-center  d-flex align-items-center">
           
             <div className='col-12 col-md-6'>
             <img src={`${process.env.PUBLIC_URL}/images/curvv/curvv-ev-book-test-drive.jfif`} className='img-fluid' style={{borderRadius:"4px"}}/>
             
             </div>
             <div className='col-12 col-md-6'>
             
             <h4 className='fw-light'>We know you will love our EVs, which is why you can book a test drive online at the nearest dealership.</h4>
             <button className='btn btn-primary' >Book Test Drive</button>
             </div>
             
            </div>
          </div>
        </motion.section>




    </>
  )
}

export default TataCurvv